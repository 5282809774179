@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	align-items: center;
	padding: 0 20/16+rem;
	height: 40/16+rem;
	border-radius: 40/2/16+rem;
	background: rgba($melrose,.3);
}

.btn {
	@extend %button;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10/16+rem;
	width: 12/16+rem;
	height: 12/16+rem;
	cursor: pointer;
}

.time {
	margin-right: 1rem;
	color: $indigo;
}

.progress {
	flex: 1 1 auto;
	height: .25rem;
	border-radius: .125rem;
	background: $melrose;
}

.fill {
	height: .25rem;
	border-radius: .125rem;
	background: $conflower-blue;
}