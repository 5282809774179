@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.label {
	margin-bottom: 8/16+rem;
	color: $silver-chalice;
}

.control {
	margin-right: 20/16+rem;
	width: 40/16+rem;
	height: 36/16+rem;
	border: 1/16+rem solid $alto;
	border-radius: .25rem;
	box-shadow: 0 0 .25rem 0 rgba($silver-chalice,.5);
}

.edit {
	width: 36/16+rem;
	height: 36/16+rem;
}