@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.p {
	color: $gray;
}

.location {

	&Selects {
		display: flex;
		align-items: center;

		i {
			margin: 0 .5rem;
		}

		span {
			margin-top: 4/16+rem;
		}
	}

	&Map {
		height: 400/16+rem;
		border: 1/16+rem solid $dusty-gray;

		iframe {
			width: 100%;
			height: 100%;
		}
	}
}