@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.item {
	display: block;
	padding: 1rem 20/16+rem;
	transition: background .25s;
	text-decoration: none;

	&:not(:last-child) {
		margin-bottom: 10/16+rem;
	}

	&:hover {
		background: $titan-white;
	}

	&Header {
		display: flex;
		align-items: center;
		margin-bottom: 10/16+rem;
	}

	&Icon {
		margin-right: 20/16+rem;
		width: 20/16+rem;
		height: 20/16+rem;
		line-height: 0;
		text-align: center;

		svg {
			width: auto;
			height: 100%;
		}

		path {
			fill: $gray;
		}
	}

	&Title {
		@include f1-700();
		font-size: 14/16+rem;
		line-height: 17/14+em;
		color: #000;
	}

	&P {
		margin-bottom: 10/16+rem;
		@include f1-300();
		font-size: 12/16+rem;
		line-height: 15/12+em;
		color: #000;
	}
}

