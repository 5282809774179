body {

	.date-picker {

		&._opened {

			&:after {
				z-index: 21;
			}
		}
	}

	.date-range-picker__wrapper-inner {
		z-index: 20;
	}
}