@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.row {
	margin-bottom: 1rem;
}