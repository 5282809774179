@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.section {
	position: fixed;
	z-index: 20;
	top: 69/16+rem;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: stretch;
	background: #fff;
	transition: transform linear 0.3s;

	&:global {

		&._hidden {
			transform: translateX(100%);

			:local {

				.arrow {
					transition: transform 0.3s 0.3s;
					transform: translateX(-30/16+rem);

					svg {
						transform: rotate(0deg);
					}
				}
			}
		}
	}
}

.arrow {
	@extend %button;
	position: absolute;
	z-index: 2;
	bottom: 12/16+rem;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30/16+rem;
	height: 30/16+rem;
	background: $melrose;
	cursor: pointer;
	transition: transform 0.3s, background 0.3s;

	&:hover {
		background: $indigo;
	}

	svg {
		transform: rotate(180deg);
	}
}

.buttons {
	padding: 1rem .5rem;
	border: 1/16+rem solid $wild-sand;
	background: #fff;
}

.btn {
	@extend %button;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44/16+rem;
	height: 44/16+rem;
	border-radius: 50%;
	cursor: pointer;
	transition: box-shadow .25s;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&:global {

		&._audiences {
			background: #F2FFE7;
		}

		&._creatives {
			background: #FFEEEE;
		}

		&._active {
			box-shadow: 0 .125rem .25rem 0 rgba(#000,.5);
		}
	}


	svg {
		width: 1.5rem;
		height: auto;
	}
}