@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.sidebar {
	display: flex;
	flex-direction: column;
	width: 320/16+rem;
	border-right: 1/16+rem solid $wild-sand;
	background: #fff;
	box-shadow: 0 0 .25rem 0 rgba($gray,.5);
	transition: transform linear 0.3s;

	&--hidden {
		transform: translateX(-100%);
	}

	&--hidden & {

		.container {
			opacity: 0;
			pointer-events: none;
			transition: opacity 0s 0.3s;
		}
	}
}

.container {
	flex: 1 1 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.header {
	flex: 0 0 auto;
	padding: 1rem;
	border-bottom: 1/16+rem solid $wild-sand;
}

.title {
	@include f1-700();
	font-size: 14/16+rem;
	line-height: 17/14+em;

	span {
		@include f1-500();
		color: $silver-chalice;
	}
}

.creative {
	display: flex;
	align-items: center;
	padding: 10/16+rem 1rem;
	border-bottom: 1/16+rem solid $wild-sand;

	&Icon {
		flex: 0 0 26/16+rem;
		margin-right: 1rem;
	}

	span {
		flex: 1 1 auto;
		overflow: hidden;
		@include f1-700();
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.main {
	flex: 1 1 auto;
	width: 100%;
	overflow: auto;
}

.content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 10/16+rem 1rem;
}