@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {

}

.list {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.item {
	@extend %button;
	flex: 0 0 33.333%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 106/16+rem;
	border-bottom: 1/16+rem solid $wild-sand;
	background: #fff;
	cursor: pointer;
	transition: .25s;
	text-decoration: none;

	&:nth-child(-n+3) {
		border-top: 1/16+rem solid $wild-sand;
	}

	&:not(:nth-child(3n)) {
		border-right: 1/16+rem solid $wild-sand;
	}

	&:hover {
		background: $titan-white;
	}

	&Title {
		transition: color .25s;
		@include f1-400();
		font-size: 14/16+rem;
		line-height: 17/14+em;
		color: $silver-chalice;
	}

	&:hover & {

		&Title {
			color: $conflower-blue;
		}
	}

	&Icon {
		margin-bottom: 22/16+rem;
		width: 24/16+rem;

		svg {
			width: 100%;
			height: auto;
		}

		path {
			fill: $gray;
		}
	}
}

