@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.file {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.label {
	margin-bottom: 4/16+rem;
	color: $gray;
}