@import "~factor/src/scss/variables";

%text {
	@include f1-400();
	font-size: 14/16+rem;
	line-height: 17/14+em;
}

.title-1 {
	@extend %text;
	@include f1-700();
	color: #000;
}

.text-2 {
	@include f1-400();
	font-size: 12/16+rem;
	line-height: 15/12+em;
	color: #000;
}

.text-label {
	display: flex;
	align-items: center;
	margin-bottom: .5rem;
	@extend %text;
	color: $silver-chalice;

	.tooltip {
		display: flex;

		path {
			fill: $gray;
		}
	}
}