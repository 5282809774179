@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1rem;
}

.preview {
	@extend %button;
	margin-bottom: 1rem;
	width: 100%;
	cursor: pointer;
	line-height: 0;

	img {
		width: 100%;
	}
}