@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.row {

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.loading {

	&FileName {
		margin-bottom: 10/16+rem;
		color: $conflower-blue;
	}

	&Progress {
		height: 5/16+rem;
		border-radius: 3/16+rem;
		background: $alto-1;
		overflow: hidden;
	}

	&Fill {
		height: 100%;
		border-radius: 3/16+rem;
		background: $conflower-blue;
	}
}

.files {
	display: flex;
	flex-wrap: wrap;
}

.file {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 .5rem .5rem 0;
	padding: 0 .5rem 0 1rem;
	height: 25/16+rem;
	border-radius: 13/16+rem;
	background: $conflower-blue;
	color: #fff;

	&Remove {

		&:global {

			&.btn-close {
				margin-left: 10/16+rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				background: #fff;

				&:before,
				&:after {
					width: 7/16+rem;
					height: 1/16+rem;
					background: $gray;
				}
			}
		}
	}
}

.item {

	&:not(:last-child) {
		margin-bottom: 10/16+rem;
	}
}

.card {
	position: relative;
	padding: 1rem 36/16+rem 1rem 1.5rem;
	min-height: 126/16+rem;
	background: #fff;
	cursor: pointer;
	transition: background .25s, border .25s;

	&:global {

		&._checked {
			border-color: rgba($conflower-blue,.5);
			background: $titan-white;

			:local {

			}
		}
	}

	&Title {
		margin-bottom: 12/16+rem;
		@include f1-700();
	}

	&Param {
		@include f1-300();

		&:not(:last-child) {
			margin-bottom: .25rem;
		}
	}

	&More {

		&:global {

			&.btn-circle {
				position: absolute;
				top: 5/16+rem;
				right: 0;
			}
		}
	}

	&Icon {
		position: absolute;
		bottom: .75rem;
		right: .75rem;

		svg {
			width: 1rem;
			height: auto;
		}

		path {
			fill: $conflower-blue;
		}
	}
}

.actions {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem .5rem .5rem;
	width: 77/16+rem;
	border-radius: 0 .25rem .25rem 0;
	border-left: 1/16+rem solid $alto-1;
	background: #fff;
	box-shadow: 0 0 .75rem 0 rgba($silver-chalice,.5);

	&Close {
		position: absolute;
		top: 20/16+rem;
		right: 15/16+rem;
		width: 10/16+rem;
		height: 10/16+rem;

		&:before,
		&:after {
			width: 14/16+rem;
		}
	}

	&Btn {
		width: 36/16+rem;
		height: 36/16+rem;
	}
}

.datespicker {

	:global {

		.date-range-picker__wrapper-inner {
			left: 0;
			right: auto;
		}
	}
}