@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.item {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 10/16+rem;
	}

	&Dragger {
		margin-left: .25rem;
	}

	&Checkbox {
		margin-right: 18/16+rem;

		&:global {

			&.checkbox {

				.checkbox {

					&__label {

						svg {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

.block {
	flex: 1 1 auto;
	padding: 1rem;
	transition: border .25s, box-shadow .25s;

	&:global {

		&._checked {
			border-color: $conflower-blue;
			box-shadow: 0 0 .75rem 0 rgba($silver-chalice,.5);
		}
	}

	&Header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}

	&Row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&Title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		path {
			fill: $gray;
		}
	}

	&Tooltip {
		margin-left: .5rem;
		line-height: 0;

		:global {

			.tooltip {

				&__label {
					line-height: 17/14+em;
				}
			}
		}
	}
}