@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
}

.description {
	margin-left: auto;

	&Title {
		@include f1-400();
		font-size: 14/16+rem;
		line-height: 17/14+em;
		color: #000;
	}

	&P {
		@include f1-300();
		font-size: 12/16+rem;
		line-height: 15/12+em;
		color: $dove-gray;
	}
}

.legend {
	display: flex;
	align-items: flex-start;
	margin-left: 40/16+rem;

	&View {
		margin-bottom: 3/16+rem;
		width: 34/16+rem;
		height: 14/16+rem;
	}

	&Value {
		font-size: 10/16+rem;
		line-height: 12/10+em;
		text-align: center;
	}

	&Item {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:not(:last-child) {
			margin-right: 10/16+rem;
		}

		&:first-child {

			.legendView {
				border: 1/16+rem solid $dusty-gray;
			}
		}
	}
}