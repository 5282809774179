@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.list {
	column-count: 2;
	column-gap: 10/16+rem;
}

.item {

	&Wrapper {
		display: inline-block;
		vertical-align: top;
		width: 100%;
	}

	.list {
		margin-left: 2rem;
		column-count: 1;
		column-gap: 0;
	}
}

.btn {
	@extend %button;
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 6/16+rem;
	padding: 0 11/16+rem 0 30/16+rem;
	height: 28/16+rem;
	width: 100%;
	border-radius: .25rem;
	overflow: hidden;
	cursor: pointer;
	transition: color .25s, background .25s;
	@include f1-400();
	font-size: 14/16+rem;
	line-height: 17/14+em;
	color: $gray;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:hover {
		background: $wild-sand;
	}

	&:global {

		&._checked {
			background: #dfe4ff;
			color: $conflower-blue;
		}
	}
}

.checked {
	position: absolute;
	top: 50%;
	left: .5rem;
	transform: translateY(-50%);
	width: 1rem;
	line-height: 0;

	svg {
		width: 100%;
		height: auto;
	}

	path {
		fill: $conflower-blue;
	}
}