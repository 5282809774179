@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.segment {
	border-bottom: 1/16+rem solid $wild-sand;
}

.header {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.image {
	margin-right: 20/16+rem;
	width: 55/16+rem;
	line-height: 0;

	img {
		width: 100%;
	}
}

.title {
	overflow: hidden;
	@include f1-700();
	font-size: 14/16+rem;
	line-height: 17/14+em;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.list {

	&Title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: .5rem;
		@include f1-500();
		font-size: 14/16+rem;
		line-height: 17/14+em;
	}
}

.item {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 6/16+rem;
	padding: 0 30/16+rem 0 11/16+rem;
	height: 28/16+rem;
	border-radius: .25rem;
	overflow: hidden;
	cursor: pointer;
	transition: color .25s, background .25s;
	color: $gray;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:hover {
		background: $wild-sand;
	}

	&:global {

		&._checked {
			background: #dfe4ff;
			color: $conflower-blue;
		}
	}
}

.selectAll {
	@extend %button;
	cursor: pointer;
	@include f1-400();
	font-size: 14/16+rem;
	line-height: 17/14+em;
	color: $conflower-blue;
}

.checked {
	position: absolute;
	top: 50%;
	right: .5rem;
	transform: translateY(-50%);
	width: 1rem;
	line-height: 0;

	svg {
		width: 100%;
		height: auto;
	}

	path {
		fill: $conflower-blue;
	}
}