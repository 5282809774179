body {

	.table {

		&__top {
			display: none;
		}

		&__cell {

			&._underline {
				text-decoration: underline;
			}

			&._checkbox {
				@include flex-width(36);
			}

			&._audience-name {
				@include flex-width(300);

				.table__cell-content {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		&__row:not(.table__row-header) {

			.table__cell {

				&._expand {
					align-items: stretch;
					padding: 0;

					.table__cell-content {
						display: flex;
						align-items: stretch;
						width: 100%;
					}
				}
			}
		}
	}
}