@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.info {
	margin-bottom: 1rem;

	&Title {
		margin-bottom: 1rem;
		@include f1-700();
	}

	&List {
		@include f1-300();

		li {
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}
}

.block {
	padding: 1rem;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.5rem;
	}

	&Row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&Title {
		display: flex;
		align-items: center;

		path {
			fill: $gray;
		}
	}

	&Tooltip {
		margin-left: .5rem;
		line-height: 0;

		:global {

			.tooltip {

				&__label {
					line-height: 17/14+em;
				}
			}
		}
	}

	&Title2 {
		margin: 2rem 0 1rem;
		font-weight: 400;
	}
}

.goal {

	&Col {
		border-left: 1/16+rem solid rgba($silver-chalice,.5);
	}
}

.p {
	color: $gray;
}

.location {

	&Selects {
		display: flex;
		align-items: center;

		i {
			margin: 0 .5rem;
		}

		span {
			margin-top: 4/16+rem;
		}
	}

	&Map {
		height: 200/16+rem;
		border: 1/16+rem solid $dusty-gray;

		iframe {
			width: 100%;
			height: 100%;
		}
	}
}

.deviceType {
	padding: 1rem;
	border: 1/16+rem solid $wild-sand;

	&Row {
		display: flex;
		margin: 0 -.25rem;
	}

	&Item {
		flex: 0 1 20%;
		min-width: 20%;
		max-width: 20%;
		padding: 0 .25rem;
	}

	&Icon {
		margin-right: .75rem;
		height: .75rem;
		line-height: 0;

		svg {
			height: 100%;
			width: auto;
		}
	}

	&Name {
		@include f1-700();
		font-size: 14/16+rem;
		line-height: 17/14+em;
		color: #000;
	}
}