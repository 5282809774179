@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.chip {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 .5rem .5rem 0;
	padding: 0 .5rem 0 1rem;
	height: 25/16+rem;
	border-radius: 13/16+rem;
	background: $conflower-blue;
	color: #fff;

	&Remove {

		&:global {

			&.btn-close {
				margin-left: 10/16+rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				background: #fff;

				&:before,
				&:after {
					width: 7/16+rem;
					height: 1/16+rem;
					background: $gray;
				}
			}
		}
	}
}