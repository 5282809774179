@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.row {

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.geo {

	&Buttons {
		display: flex;
	}

	&Btn {
		@extend %button;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 37/16+rem;
		height: 37/16+rem;
		border-radius: 50%;
		background: $conflower-blue;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 10/16+rem;
		}
	}
}

.chips {
	display: flex;
	flex-wrap: wrap;

	&Item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 .5rem .5rem 0;
		padding: 0 .5rem 0 1rem;
		height: 25/16+rem;
		border-radius: 13/16+rem;
		background: $conflower-blue;
		color: #fff;
	}

	&Remove {

		&:global {

			&.btn-close {
				margin-left: 10/16+rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				background: #fff;

				&:before,
				&:after {
					width: 7/16+rem;
					height: 1/16+rem;
					background: $gray;
				}
			}
		}
	}
}

.map {
	height: 206/16+rem;
	border: 1/16+rem solid $dusty-gray;

	iframe {
		width: 100%;
		height: 100%;
	}
}

.datespicker {

	:global {

		.date-range-picker__wrapper-inner {
			left: 0;
			right: auto;
		}
	}
}