@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	:global {

		.tooltip {

			&__label {
				width: 150/16+rem;
				white-space: normal;
			}
		}
	}
}

.row {
	margin-bottom: 1rem;
}