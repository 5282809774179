@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.row {
	margin-bottom: 1rem;
}

.comment {
	align-self: flex-start;
	margin-top: 1rem;
	color: $fruit-salad;
}

.block {
	padding: 1rem .5rem;
	border-radius: .25rem;
	border: 1/16+rem solid $alto-1;
	box-shadow: 0 0 .75rem 0 rgba($mercury,.5);
}

.fileName {
	@include f1-700();
}

.audioFileMore {
	width: 1.5rem;
	height: 1.5rem;
}