@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.sidebar {
	display: flex;
	flex-direction: column;
	width: 320/16+rem;
	background: #fff;
	box-shadow: 0 0 .25rem 0 rgba($gray,.5);
	transition: transform linear 0.3s;
}

.container {
	flex: 1 1 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden;
}

.row {

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.header {
	flex: 0 0 auto;

	&Wrapper {
		display: flex;
		align-items: center;
		padding: 0 1rem;
		min-height: 50/16+rem;
	}

	&Search {
		padding: .75rem 1rem 6/16+rem;
	}
}

.icon {
	margin-right: 1rem;
	min-width: 22/16+rem;
	max-width: 22/16+rem;
	line-height: 0;

	svg {
		width: 100%;
		height: auto;
	}
}

.title {
	margin-right: auto;
	@include f1-700();
	font-size: 20/16+rem;
	line-height: 24/20+em;
	color: #000;
}

.creative {
	display: flex;
	align-items: center;
	padding: 10/16+rem 1rem;
	border-bottom: 1/16+rem solid $wild-sand;

	&Icon {
		flex: 0 0 26/16+rem;
		margin-right: 1rem;
	}

	span {
		flex: 1 1 auto;
		overflow: hidden;
		@include f1-700();
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.main {
	flex: 1 1 auto;
	width: 100%;
	overflow: auto;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 12/16+rem 1rem;
}

.footer {
	position: relative;
	display: flex;
	justify-content: flex-end;
	padding: 1rem;

	&Btn {

		&:not(:last-child) {
			margin-right: 1rem;
		}
	}
}

.create {

	&:global {

		&.btn-square {
			min-width: 90/16+rem;
			padding: 0 1em;
		}
	}
}