@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.row {
	margin-bottom: 1rem;
}

.list {
	margin-bottom: 1rem;
}

.item {
	display: flex;
	align-items: stretch;
	flex-direction: column;
	border-radius: .25rem;
	border: 1/16+rem solid $alto-1;
	box-shadow: 0 0 .75rem 0 rgba($mercury,.5);

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&Wrapper {
		padding: .5rem 1rem 1rem;

		:global {

			.text-field {

				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}
		}
	}

	&Title {
		margin-bottom: 1rem;
	}
}

.image {
	border-radius: .25rem .25rem 0 0;
	overflow: hidden;
	line-height: 0;

	img {
		width: 100%;
	}
}