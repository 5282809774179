@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.row {
	margin-bottom: 1rem;
}

.item {

	&:not(:last-child) {
		margin-bottom: .75rem;
	}
}