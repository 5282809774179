@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.main {
	display: flex;
	align-items: stretch;
	padding: 70/16+rem 0 0;
	min-width: 1280/16+rem;
	min-height: 100vh;
	transition: all 0.3s linear;

	@include over-xlg() {

		&:global {

			&._opened {
				padding-left: 320/16+rem;
			}
		}
	}

	&:global {

		&._right-sidebar-opened {
			padding-right: 62/16+rem;
		}
	}
}