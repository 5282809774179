@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.row {

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.checkbox {

	&:not(:last-child) {
		margin-bottom: .25rem;
	}
}