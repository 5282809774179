* {
	box-sizing: border-box;
}

html {
	margin: 0;
	font-size: 16px;
}

body {
	margin: 0;
	@extend %text;
	-webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0;
	padding: 0;
	list-style: none;
}