//colors
$titan-white: #EBEEFF;
$alto-2: #d1d1d1;
$mercury: #e8e8e8;
$dove-gray-1: #696969;
$mine-shaft-1: #242424;

$manhattan: #f7ce85;

$titan-white: #EBEEFF;
$dodger-blue-1: #3399FF;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;